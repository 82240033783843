import antdEnUS from "antd/es/locale/en_US";
import antdZhCn from "antd/es/locale/zh_CN";
import antdKoKR from "antd/es/locale/ko_KR";
import antdJaJP from "antd/es/locale/ja_JP";
import en from "./locales/en_US.json";
import zh from "./locales/zh_CN.json";
import ko from "./locales/ko_KR.json";
import ja from "./locales/ja_JP.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { THEME_CONFIG } from "configs/AppConfig";

export const resources = {
  en: {
    translation: en,
    antd: antdEnUS,
  },
  "zh-Hans": {
    translation: zh,
    antd: antdZhCn,
  },
  ko: {
    translation: ko,
    antd: antdKoKR,
  },
  ja: {
    translation: ja,
    antd: antdJaJP,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: THEME_CONFIG.locale,
  lng: THEME_CONFIG.locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
